import './aitemplate.css'
import { addGlobalFont } from 'polotno/config'
import { isRuLocale } from '../utils/localization.utils'


export const deleteTemplate = isRuLocale()
  ? require("./templates/ru/deleteTemplate").deleteTemplate
  : require("./templates/en/deleteTemplate").deleteTemplate
export const infoTemplates = isRuLocale()
  ? require("./templates/ru/infoTemplates").infoTemplates
  : require("./templates/en/infoTemplates").infoTemplates
export const informationTemplates = isRuLocale()
  ? require("./templates/ru/informationTemplates").informationTemplates
  : require("./templates/en/informationTemplates").informationTemplates

addGlobalFont({
  fontFamily: 'Angry',
  url: '/infographics/fonts/Angry.otf',
})

addGlobalFont({
  fontFamily: 'Soyuz Grotesk',
  url: '/infographics/fonts/SoyuzGrotesk.otf',
})

addGlobalFont({
  fontFamily: 'Onest-Light',
  url: '/infographics/fonts/Onest-Light.ttf',
})

addGlobalFont({
  fontFamily: 'Onest-ExtraBold',
  url: '/infographics/fonts/Onest-ExtraBold.ttf',
})

addGlobalFont({
  fontFamily: 'Onest-Bold',
  url: '/infographics/fonts/Onest-Bold.ttf',
})

addGlobalFont({
  fontFamily: 'Onest-SemiBold',
  url: '/infographics/fonts/Onest-SemiBold.ttf',
})

addGlobalFont({
  fontFamily: 'Onest-VariableFont',
  url: '/infographics/fonts/Onest-VariableFont_wght.ttf',
})


