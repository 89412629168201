import { Helmet } from 'react-helmet'

interface DynamicTitleProps {
  title: string, 
}

export const DynamicTitle = (props: DynamicTitleProps) => {
  const {title} = props
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}
