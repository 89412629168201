import { createBrowserRouter } from 'react-router-dom'
import {
  R_START_PAGE,
  R_SIGN_IN_PAGE,
  R_FORGOT_PASSWORD_PAGE,
  R_SIGN_UP_PAGE,
  R_THANKS_SIGN_UP,
  R_PLANS,
  R_USER_PROFILE,
  R_PROJECT_PAGE, R_ADD_DEMO_PLAN,
} from './router-constants'

// import { LoginPage, EditorPage, RegisterPage, FileUploadPage } from './pages'
import {
  AuthProtectedPage,
  SignInPage,
  StartPage,
  ForgotPasswordPage,
  SignUpPage,
  ThanksForSignUpPage,
  PlansPage,
  UserProfilePage,
  ProjectWorkspacePage,
  DemoPlanPage,
} from './pages/index'
import { CompletedRegistrationProtectedPage } from './pages/CompletedRegistrationProtectedPage'
import { DynamicTitle } from './components/new-editor/components/DynamicTitle'

export const router = createBrowserRouter([
  {
    path: R_SIGN_IN_PAGE,
    element: (
      <>
        <DynamicTitle
          title='Вход в личный кабинет MPCard'/>
        <SignInPage />
      </>
    ),
  },
  {
    path: R_SIGN_UP_PAGE,
    element: (
      <>
        <DynamicTitle 
          title='Регистрация на сервисе MPCard'/>
        <SignUpPage />
      </>
    ),
  },
  {
    path: R_FORGOT_PASSWORD_PAGE,
    element: (
      <>
        <DynamicTitle 
          title='Восстановление пароля на сервисе MPCard'/>
        <ForgotPasswordPage />
      </>
    ),
  },
  {
    path: R_THANKS_SIGN_UP,
    element: (
      <>
        <DynamicTitle 
          title='Спасибо за регистрацию на сервисе MPCard'/>
        <ThanksForSignUpPage />
      </>
    ),
  },
  {
    path: R_PLANS,
    element: (
      <>
        <DynamicTitle
          title='Тарифы MPCard'/>
        <PlansPage />
      </>
    ),
  },
  {
    element: <AuthProtectedPage />,
    children: [
      {
        element: <CompletedRegistrationProtectedPage />,
        children: [
          {
            path: R_START_PAGE,
            element: (
              <>
                <DynamicTitle
                  title='MPCard конструктор карточек'
                  />
                <StartPage />
              </>
            ),
          },
          {
            path: R_PROJECT_PAGE,
            element: (
              <>
                <DynamicTitle
                  title='Проект MPCard'/>
                <ProjectWorkspacePage />
              </>
            ),
          }
        ]
      },
      {
        path: R_USER_PROFILE,
        element: (
          <>
            <DynamicTitle
              title='Профиль пользователя MPCard'/>
            <UserProfilePage />
          </>
        ),
      },
      {
        path: R_ADD_DEMO_PLAN,
        element: (
          <>
            <DynamicTitle
              title='Добавить демо-план MPCard'/>
            <DemoPlanPage />
          </>
        ),
      }
    ],
  },
])
