import {StoreType} from "polotno/model/store";
import {createImage, setCustomStoreEntityProps, setObjectBeforeDisplacement} from "./image.utils";
import {centerObjectInPage} from "./editor.utils";
import {Meta, ProjectObject, State} from "../../../domain/types/Project";
import {updateProject} from "../../../api/projects/update-project";
import debounce from "lodash/debounce";
import { ProductType } from "../sections/AiBackgrounds/types";
import {PageType} from "polotno/model/page-model";
import {updateCover} from "../../../api/projects/update-cover";

export enum BackgroundType {
    Suggested = "suggested",
    Template = "template",
    Prompt = "prompt",
    Default = "default",
    Static = "static"
}

const DEFAULT_OBJECT_PROPS = {
    resizable: true,
    keepRatio: true,
    removable: false,
    name: 'object',
    type: 'image',
}

export const addProjectObject = async (store: StoreType, sourceImage: string): Promise<void> => {
    const img = await createImage(sourceImage)
    const currentPageId = store.activePage.id

    return loadImage(store, currentPageId, img, sourceImage)
}

export const getObject = (page: PageType, store: StoreType) => page.children.find((c: any) => ['object', 'product_replace'].includes(c.name))
    || store.getElementById(`object_${page.id}`) || store.getElementById(`object`)

const addObjectToPage = (page: any, position: Record<string, number>, imageUrl: string) => {
    page.addElement({
        ...DEFAULT_OBJECT_PROPS,
        ...position,
        src: imageUrl,
        id: `object_${page.id}`,
        custom: { activeBackgroundType: BackgroundType.Default, objectPositionBeforeDisplacement: {
                ...position,
                rotation: 0,
            } }
    })
}

const loadImage = async(store: StoreType, currentPageId: string, img: HTMLImageElement, imageUrl: string): Promise<void> => new Promise((resolve, reject) => {
    img.onload = async () => {
        let targetPage = store.pages.find((p: {id: string}) => p.id === currentPageId)
        if (!targetPage || getObject(targetPage, store)) {
            resolve()
            return
        }
        const position = centerObjectInPage(img, targetPage)
        addObjectToPage(targetPage, position, imageUrl)
        resolve()
    }

    img.onerror = (error) => {
        console.error('Failed to load image:', error)
        reject()
    }
})

export const createNewPage = async (store: StoreType) => {
    store.addPage()
    store.activePage.setSize({width: store.width, height: store.height})
    await store.waitLoading()
}

export const createNewCover = async (store: StoreType ) => {
    const response = await updateCover(await store.toBlob({ pixelRatio: 0.5, quickMode: true }))
    return response.status? response.url : null
}

const saveCurrentProject = async (projectId: string, state: Partial<State>, meta: Meta) => {
    return await updateProject(projectId, state, meta)
}

const getMeta = (meta: Meta | null | undefined, coverUrl: string | null | undefined) => ({
    ...meta,
    coverImage: (coverUrl ?? meta?.coverImage) || "",
})

const getProjectState = (store: StoreType, project: ProjectObject, {cards, infographics}: any) => ({
    sourceImage: project.state.sourceImage,
    polotnoStore: store.toJSON(),
    infographicsTemplate: store.custom.activeInfographicsTemplate || 0,
    cardSize: store.custom.cardSize,
    infographicData: infographics,
    cards,
})

export const saveProject = debounce(async (project: ProjectObject, store: StoreType, externalState: any, finalize: () => unknown) => {
    let coverUrl = await createNewCover(store)

    try {
        const projectState = getProjectState(store, project, externalState)
        const meta = getMeta(project.meta, coverUrl);
        await saveCurrentProject(project.id, projectState, meta)
    } catch (e) {
        console.log('Unable to save project', e)
    } finally {
        finalize()
    }
}, 3000)

const isMainObject = ((e: any, store: StoreType) => ['object', 'product_replace'].includes(e.name) ||
    e.id === `object_${store.activePage.id}`)

export const getMainObjectBlob = async (store: any, product: ProductType) => {
    for (let element of store.activePage.children) {
        if (isMainObject(element, store)) continue
        element.set({ showInExport: false })
    }
    const oldOpacity = product?.opacity //Dirty fix to not create backgrounds with transparent object due to strange behaviour

    product?.set({ opacity: 100 })
    const dataUrl = await store.toBlob({
        pageId: store.activePage.id,
        ignoreBackground: true,
        mimeType: 'image/webp', // ❗️ SET image/png if comfy is in use on the back-end side OTHERWISE COMFY WILL FAIL WITH AN ERROR
    })

    product?.set({ opacity: oldOpacity })
    for (let element of store.activePage.children) {
        if (isMainObject(element, store)) continue
        element.set({ showInExport: true })
    }
    return dataUrl
}

export const getMainObjectDataUrl = async (store: any, product: ProductType, ignoreBg: boolean) => {
    const page = product.page;
    for (let element of page.children) {
        if (element.id === `object_${page.id}`) continue
        element.set({ showInExport: false })
    }
    const oldOpacity = product.opacity //Dirty fix to not create backgrounds with transparent object due to strange behaviour

    product.set({ opacity: 100 })
    const dataUrl = await store.toDataURL({
        pageId: page.id,
        ignoreBackground: ignoreBg,
        mimeType: 'image/png',
    })

    product.set({ opacity: oldOpacity })
    for (let element of page.children) {
        if (element.id === `object_${page.id}`) continue
        element.set({ showInExport: true })
    }
    return dataUrl
}

export const applyBackground = function(store: StoreType, imageUrl: string) {
    const object = getObject(store.activePage, store);
    setObjectBeforeDisplacement(object)
    setCustomStoreEntityProps(object, { forceShowPositionChanged: false })
    store.activePage.set({ background: imageUrl })
}

export const processBackground = async (store: StoreType, product: any, generateBackground:any, prompt: string, enhancePrompt = false ) => {
    const blob = await getMainObjectBlob(store, product)

    const res = await generateBackground(blob, prompt, '', { translate: true, enhance: enhancePrompt })
    if(res.savedMaskUrl) setCustomStoreEntityProps(product, { maskUrl: res.savedMaskUrl})
    if (res.imageUrl) {
        setCustomStoreEntityProps(store, { imageUrl: res.imageUrl })
        applyBackground(store, res.imageUrl)
    } else {
        console.error('Bad image url', res)
    }
    return res;
}

export const createProjectStatParams = (store: StoreType, product: any) => {
    const background = store.activePage.background
    const params = {
        backgroundUrl: background,
        backgroundType: product?.custom?.activebackgroundType,
        backgroundKey: product?.custom?.activeBackgroundKey,
        backgroundPrompt: store.custom.currentPromptInStore?.prompt || "",
        infographicsTemplate: store.custom.activeInfographicsTemplate || 0
    }
    if (background[0] === "#" || background.includes('http') || background.includes('rgb(')) {
        params.backgroundType = BackgroundType.Static
    }
    if (background.includes('infographics') || background === "white" || background === "transparent") {
        params.backgroundType = BackgroundType.Default
    }
    return params
}