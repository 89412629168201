import { deleteTemplate } from '../aicard/infographics'
import { placeObjectInTemplate, setCustomStoreEntityProps } from './image.utils'
import { ProductType } from '../sections/AiBackgrounds/types'
import { cloneDeep } from 'lodash'

export function centerObjectInPage(
  { naturalWidth, naturalHeight }: any,
  { width, height }: any
): Record<string, number> {
  const object = { width: naturalWidth, height: naturalHeight }
  const rect = { x: width * 0.2, y: height * 0.2, width: width * 0.6, height: height * 0.6 }
  return centerObjectInRect(object, rect)
}

export function centerObjectInRect(object: any, rect: any): Record<string, number> {
  const scaleByHeight = object.height / object.width > rect.height / rect.width
  const result = { x: 0, y: 0, width: 0, height: 0 }
  if (scaleByHeight) {
    result.width = object.width * (rect.height / object.height)
    result.height = rect.height
  } else {
    result.height = object.height * (rect.width / object.width)
    result.width = rect.width
  }

  result.x = rect.x + rect.width / 2 - result.width / 2
  result.y = rect.y + rect.height / 2 - result.height / 2
  return result
}

export function getCenterPlacement(
  storeWidth: number,
  storeHeight: number,
  imageWidth: number,
  imageHeight: number
) {
  return { x: storeWidth / 2 - imageWidth / 2, y: storeHeight / 2 - imageHeight / 2 }
}

export function getScaledSize(
  imageWidth: number,
  imageHeight: number,
  limitWidth: number,
  limitHeight: number
) {
  if (imageWidth <= limitWidth && imageHeight <= limitHeight)
    return { height: imageHeight, width: imageWidth }
  const scaleByHeight = imageHeight / imageWidth > limitHeight / limitWidth
  const result = { width: 0, height: 0 }
  if (scaleByHeight) {
    result.width = imageWidth * (limitHeight / imageHeight)
    result.height = limitHeight
  } else {
    result.height = imageHeight * (limitWidth / imageWidth)
    result.width = limitWidth
  }
  return result
}

export const getLegacyProjectObject = (store: any) => store.getElementById(`object`)
export const getMainObject = (store: any) => getPageObject(store, store.activePage)
export const getPageObject = (store: any, page: any) => {
  return (
    page.children.find((c: any) => ['object', 'product_replace'].includes(c.name)) ||
    store.getElementById(`object_${page.id}`) ||
    getLegacyProjectObject(store)
  )
}
export const addPageTemplate = async (store: any, page: any, template: any, options: any) => {
  const {
    infographicsData,
    replaceTitleInfographicsTemplate,
    element,
    replaceAboutTextInfographicsTemplate,
    setPositionChangedInFunction,
  } = options
  let currentObject = cloneDeep(getPageObject(store, page))
  await store.waitLoading()

  store.deleteElements(page.children.map((el:any)=>el.id))
  store.waitLoading()

  if (!page.background.includes('/') || page.background.includes('infographics')) {
    page.set({ background: template['pages'][0]['background'] })
  }

  for (let templateElement of template['pages'][0]['children']) {
    if (templateElement.id === 'object' && currentObject) {
      const cloneElement = cloneDeep(templateElement) //placeObjectInTemplate modifies passed element, that's why we need a clone

      placeObjectInTemplate(store, page, currentObject, cloneElement, {
        jsonTemplate: template,
        setPositionChangedInFunction,
      })
    } else {
      page.addElement({
        ...templateElement,
        id: `${templateElement.id}_${page.id}`,
      })
    }
  }

  if (infographicsData !== null && !infographicsData.error && element.key !== 0) {
    replaceTitleInfographicsTemplate(element, infographicsData.heading, store)
    replaceAboutTextInfographicsTemplate(
      element,
      infographicsData.features,
      store,
      infographicsData
    )
  }

  if (infographicsData !== null && element.key !== 0) {
    for (let i = 0; i < element.numberOfHeaderParts; i++) {
      store
        .getElementById(`main_text_replace_${i}_${page.id}`)
        ?.set({ verticalAlign: 'middle' })
    }
    store
      .getElementById(`first_about_text_${page.id}`)
      ?.set({ verticalAlign: 'middle' })
    store
      .getElementById(`second_about_text_${page.id}`)
      ?.set({ verticalAlign: 'middle' })
    store
      .getElementById(`third_about_text_${page.id}`)
      ?.set({ verticalAlign: 'middle' })
  }
  setCustomStoreEntityProps(store, { infographicsApplied: element.key !== deleteTemplate.key })
}

export const addPageInformationTemplate = async (store: any, page: any, template: any) => {
  await store.waitLoading()
  store.deleteElements(page.children.map((el:any)=>el.id))
  store.waitLoading()
  if (!page.background.includes('/') || page.background.includes('infographics')) {
    page.set({ background: template['pages'][0]['background'] })
  }

  for (let templateElement of template['pages'][0]['children']) {
    page.addElement({
      ...templateElement,
      id: `${templateElement.id}_${page.id}`,
    })
  }
}

export const positionChanged = (a: ProductType, b: ProductType) =>
  a.x !== b.x ||
  a.y !== b.y ||
  a.width !== b.width ||
  a.height !== b.height ||
  a.rotation !== b.rotation

export const getObjectPosition = (product: ProductType) =>
  product
    ? {
        x: product.x,
        width: product.width,
        height: product.height,
        y: product.y,
        rotation: product.rotation,
      }
    : {}
