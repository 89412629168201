import axios from 'axios'
import { backendUrl } from '../config/app'

export function useSocialAuthHandler(provider: 'yandex' | 'vk') {
  return async (marketingConsent?: boolean) => {
    const response = await axios.post(
      `${backendUrl()}api/social-auth/start`,
      { p: provider, marketingConsent },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (response.data && response.data.url) {
      window.location.href = response.data.url
    } else {
      throw new Error('Response has no required fields')
    }
  }
}
