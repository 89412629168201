import './aitemplate.css'
import {reachGoal} from "../../../utils/metrics";
import {usePolotno} from "../../../store/usePolotno";
import { useTranslation } from 'react-i18next'
import {setCustomStoreEntityProps} from "../utils/image.utils";

export const templateCategories = [
  {
    name: 'backgroundTemplates.atmosphereCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.bokehEffectTemplate',
        image: '/background_templates/at_0.jpg',
        prompt:
            'a professional photo of a product placed in front of a softly blurred bokeh background with subtle sparkling lights, crisp focus on the product, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: '0',
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.minimalismTemplate',
        image: '/background_templates/at_1.jpg',
        prompt:
            'a professional photo of a product displayed on a clean, minimalist background with soft neutral tones, simple and refined composition, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 1,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.flowerTemplate',
        image: '/background_templates/at_2.jpg',
        prompt: 'a professional photo of a product surrounded by vibrant flowers and lush petals, fresh botanical ambiance, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 2,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.winterTemplate',
        image: '/background_templates/at_3.jpg',
        prompt:
            'a professional photo of a product set in a serene winter scene with gently falling snow, a quiet winter street in the background, crisp cold atmosphere, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 3,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.autumnTemplate',
        image: '/background_templates/at_4.jpg',
        prompt:
            'a professional photo of a product in a warm autumn setting with fallen leaves, an autumn street and park in the background, rich seasonal colors, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 4,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.beachTemplate',
        image: '/background_templates/at_5.jpg',
        prompt:
            'a professional photo of a product on a tropical beach with turquoise waters, palm trees swaying nearby, bright sunlight, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 5,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.mountainTemplate',
        image: '/background_templates/at_6.jpg',
        prompt:
            'a professional photo of a product framed by majestic mountains, snow-capped peaks in the distance, fresh alpine air, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 6,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.stumpTemplate',
        image: '/background_templates/at_7.jpg',
        prompt:
            'a professional photo of a product placed on a tree stump surrounded by herbs and ferns, with a marsh and dense forest in the background, natural rustic atmosphere, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 7,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.cloudTemplate',
        image: '/background_templates/at_8.jpg',
        prompt:
            'a professional photo of a product floating on soft, billowing clouds, dreamy ethereal look, bright sky overhead, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 8,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.sunsetTemplate',
        image: '/background_templates/at_9.jpg',
        prompt:
            'a professional photo of a product at sunset, placed on a windowsill overlooking nature, warm golden light, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 9,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.goldenHourTemplate',
        image: '/background_templates/at_10.jpg',
        prompt:
            'a professional photo of a product illuminated by the golden hour sun, soft natural light and gentle shadows, warm ambiance, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 10,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.streetTemplate',
        image: '/background_templates/at_11.jpg',
        prompt:
            'a professional photo of a product placed in a lively outdoor scene, featuring a city street, park, and tree-lined avenue, vibrant urban atmosphere, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 11,
      },
      {
        name: 'backgroundTemplates.atmosphereCategory.templates.romanticTemplate',
        image: '/background_templates/at_12.jpg',
        prompt:
            'a professional photo of a product adorned with heart shapes and romantic details, soft pastel tones, love theme in the background, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
        key: 12,
      },
    ],
  },
  {
    name: 'backgroundTemplates.holidaysCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.holidaysCategory.templates.birthdayTemplate',
        key: 13,
        image: '/background_templates/pr_0.jpg',
        prompt:
            'a professional photo of a product with a birthday party theme background, festive and cheery, award winning photography',
        negativePrompt:
            'human, person, child, face, letters, text, oversaturated, ugly, 3d, render, cartoon, grain, low-res, lens, camera',
      },
      {
        name: 'backgroundTemplates.holidaysCategory.templates.weddingTemplate',
        key: 14,
        image: '/background_templates/pr_1.jpg',
        prompt:
            'a professional photo of a product in an elegant wedding setting, decorated with soft floral arrangements, flowing white fabrics, and delicate wedding ornaments, award winning photography',
        negativePrompt:
            'human, person, child, face, letters, text, oversaturated, ugly, 3d, render, cartoon, grain, low-res, lens, camera',
      },
      {
        name: 'backgroundTemplates.holidaysCategory.templates.genderPartyTemplate',
        key: 15,
        image: '/background_templates/pr_2.jpg',
        prompt:
            'a professional photo of a product at a joyful baby shower event, featuring playful pink and blue decorations, balloons, and confetti, gender reveal elements, award winning photography',
        negativePrompt:
            'human, person, child, face, letters, text, oversaturated, ugly, 3d, render, cartoon, grain, low-res, lens, camera',
      },
      {
        name: 'backgroundTemplates.holidaysCategory.templates.easterTemplate',
        key: 16,
        image: '/background_templates/pr_3.jpg',
        prompt:
            'a professional photo of a product placed on a lush grassy field, surrounded by pastel Easter eggs and spring flowers, cheerful holiday atmosphere, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.holidaysCategory.templates.newYearTemplate',
        key: 17,
        image: '/background_templates/pr_4.jpg',
        prompt:
            'a professional photo of a product in a cozy Christmas scene, adorned with festive ornaments, evergreen branches, and soft glowing lights, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.tablesCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.tablesCategory.templates.lightWoodTemplate',
        key: 18,
        image: '/background_templates/st_0.jpg',
        prompt:
            'a professional photo of a product showcased on a smooth light wood table, subtle woodgrain texture, warm natural lighting, hd light wood wallpaper backdrop, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.darkWoodTemplate',
        key: 19,
        image: '/background_templates/st_1.jpg',
        prompt:
            'a professional photo of a product displayed on a richly grained dark wood countertop, moody lighting, hd dark wood wallpaper backdrop, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.redWoodTemplate',
        key: 20,
        image: '/background_templates/st_2.jpg',
        prompt:
            'a professional photo of a product placed on a dark red wood surface, bold woodgrain patterns, warm dramatic tones, hd dark red wood wallpaper backdrop, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.concreteTemplate',
        key: 21,
        image: '/background_templates/st_3.jpg',
        prompt:
            'a professional photo of a product set on a sleek concrete countertop, industrial style, subtle gray textures, hd concrete wallpaper backdrop, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.whiteMarbleTemplate',
        key: 22,
        image: '/background_templates/st_4.jpg',
        prompt:
            'a professional photo of a product positioned on a polished white marble table, elegant veining, bright refined lighting, hd white marble wallpaper backdrop, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.blackMarbleTemplate',
        key: 23,
        image: '/background_templates/st_5.jpg',
        prompt:
            'a professional photo of a product on a glossy black marble surface, striking white veining, dramatic reflections, hd black marble wallpaper backdrop, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.brownMarbleTemplate',
        key: 24,
        image: '/background_templates/st_6.jpg',
        prompt:
            'a professional photo of a product placed on a luxurious brown marble tabletop, rich earthy veins, sophisticated atmosphere, hd brown marble wallpaper backdrop, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.creamMarbleTemplate',
        key: 25,
        image: '/background_templates/st_7.jpg',
        prompt:
            'a professional photo of a product resting on a smooth cream marble surface, soft golden veins, warm natural lighting, hd cream marble wallpaper backdrop, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.tablesCategory.templates.greenMarbleTemplate',
        key: 26,
        image: '/background_templates/st_8.jpg',
        prompt:
            'a professional photo of a product arranged on a vibrant green marble table, intricate marble patterns, fresh elegant ambiance, hd green marble wallpaper backdrop, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.plantsCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.plantsCategory.templates.succulentTemplate',
        key: 27,
        image: '/background_templates/pl_0.jpg',
        prompt:
            'a professional photo of a product displayed among various succulent plants in stylish ceramic pots, natural warm lighting, modern indoor setting, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.plantsCategory.templates.monsteraTemplate',
        key: 28,
        image: '/background_templates/pl_1.jpg',
        prompt:
            'a professional photo of a product placed near lush monstera leaves, vibrant tropical ambiance, soft sunlight filtering through leaves, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.plantsCategory.templates.cactusTemplate',
        key: 29,
        image: '/background_templates/pl_2.jpg',
        prompt: 'a professional photo of a product surrounded by tall cacti of different shapes, desert-inspired decor, bright and clear lighting, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.plantsCategory.templates.tillandsiaTemplate',
        key: 30,
        image: '/background_templates/pl_3.jpg',
        prompt:
            'a professional photo of a product highlighted by clusters of hanging air plants and tillandsias, minimal earthy decor, softly diffused light, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.plantsCategory.templates.hoyaTemplate',
        key: 31,
        image: '/background_templates/pl_4.jpg',
        prompt:
            'a professional photo of a product arranged among trailing hoya plants with waxy leaves, gentle natural lighting, serene indoor garden look, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.plantsCategory.templates.pileaTemplate',
        key: 32,
        image: '/background_templates/pl_5.jpg',
        prompt:
            'a professional photo of a product surrounded by pilea plants in modern planters, bright and fresh indoor environment, soft diffused daylight, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.interiorCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.interiorCategory.templates.cozyHomeTemplate',
        key: 33,
        image: '/background_templates/in_0.jpg',
        prompt:
            'a professional photo of a product in a cozy home interior, warm soft furnishings, gentle sunlight through a window, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.interiorCategory.templates.bathroomTemplate',
        key: 34,
        image: '/background_templates/in_1.jpg',
        prompt:
            'a professional photo of a product on a sleek bathroom counter, framed by luxurious fixtures and spa-like details, crisp lighting, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.interiorCategory.templates.kitchenTemplate',
        key: 35,
        image: '/background_templates/in_2.jpg',
        prompt:
            'a professional photo of a product placed on a polished countertop with a blurred modern kitchen in the background, bright and inviting atmosphere, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.interiorCategory.templates.kidsRoomTemplate',
        key: 36,
        image: '/background_templates/in_3.jpg',
        prompt:
            "a professional photo of a product set on a countertop with a playful children's room in the background, colorful toys and decor, cheerful lighting, award winning photography",
        negativePrompt:
            'human, person, child, face, letters, text, oversaturated, ugly, 3d, render, cartoon, grain, low-res, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.foodCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.foodCategory.templates.grapeTemplate',
        key: 37,
        image: '/background_templates/fo_0.jpg',
        prompt:
            'a professional photo of a product on a marble countertop surrounded by fresh grapes, with a lush vineyard view behind, vibrant natural lighting, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.foodCategory.templates.coffeeTemplate',
        key: 38,
        image: '/background_templates/fo_1.jpg',
        prompt:
            'a professional photo of a product on a wooden table sprinkled with coffee beans, in front of a luxurious cafe backdrop, warm cozy lighting, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.foodCategory.templates.berryTemplate',
        key: 39,
        image: '/background_templates/fo_2.jpg',
        prompt:
            'a professional photo of a product on a tabletop alongside bowls of assorted berries, soft natural light, fresh and colorful setting, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.flowersCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.flowersCategory.templates.rosesTemplate',
        key: 40,
        image: '/background_templates/fl_0.jpg',
        prompt: 'a professional photo of a product showcased among lush rose blossoms and petals, soft romantic lighting, vibrant floral accents, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.flowersCategory.templates.tulipsTemplate',
        key: 41,
        image: '/background_templates/fl_1.jpg',
        prompt: 'a professional photo of a product surrounded with tulip flowers and tulip leaves, fresh floral look, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.flowersCategory.templates.lavenderTemplate',
        key: 42,
        image: '/background_templates/fl_2.jpg',
        prompt:
            'a professional photo of a product surrounded by fragrant lavender blooms, gentle purple hues, tranquil countryside ambiance, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.flowersCategory.templates.cherryBlossomTemplate',
        key: 43,
        image: '/background_templates/fl_3.jpg',
        prompt:
            'a professional photo of a product placed amidst delicate cherry blossom branches, pastel pink petals, dreamy spring atmosphere, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.texturesCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.texturesCategory.templates.soilTemplate',
        key: 44,
        image: '/background_templates/te_0.jpg',
        prompt: 'a professional photo of a product partially immersed in rich, textured soil, earthy natural tones, subtle outdoor lighting, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.texturesCategory.templates.waterTemplate',
        key: 45,
        image: '/background_templates/te_1.jpg',
        prompt:
            'a professional photo of a product positioned on a reflective liquid surface, dynamic water splashes, ripples in studio lighting, hd watery backdrop, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.texturesCategory.templates.fabricTemplate',
        key: 46,
        image: '/background_templates/te_2.jpg',
        prompt: 'a professional photo of a product draped with softly flowing fabric, subtle folds and textures, warm diffused lighting, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.texturesCategory.templates.marbleTemplate',
        key: 47,
        image: '/background_templates/te_3.jpg',
        prompt:
            'a professional photo of a product placed on a polished marble table with elegant veining, luxurious setting, refined lighting, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.citiesCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.citiesCategory.templates.newYorkTemplate',
        key: 48,
        image: '/background_templates/ci_0.jpg',
        prompt:
            'a professional photo of a product placed by a large window showcasing the iconic New York skyline, skyscrapers in the distance, vibrant city atmosphere, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.citiesCategory.templates.parisTemplate',
        key: 49,
        image: '/background_templates/ci_1.jpg',
        prompt:
            'a professional photo of a product positioned in front of a floor-to-ceiling window with a panoramic view of Paris, the Eiffel Tower in the distance, romantic European ambiance, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.citiesCategory.templates.marrakechTemplate',
        key: 50,
        image: '/background_templates/ci_2.jpg',
        prompt:
            'a professional photo of a product displayed in a stylish interior with a grand window revealing Marrakech’s sunlit rooftops and minarets, warm exotic lighting, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.citiesCategory.templates.mykonosTemplate',
        key: 51,
        image: '/background_templates/ci_3.jpg',
        prompt:
            'a professional photo of a product set near a big window overlooking Mykonos’s white-washed houses and the sparkling Aegean Sea, bright Mediterranean sunlight, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.citiesCategory.templates.veniceTemplate',
        key: 52,
        image: '/background_templates/ci_4.jpg',
        prompt:
            'a professional photo of a product placed by a wide window framing Venice’s historic canals and architecture, romantic Italian atmosphere, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.citiesCategory.templates.okinawaTemplate',
        key: 53,
        image: '/background_templates/ci_5.jpg',
        prompt:
            'a professional photo of a product positioned in front of a large window with turquoise Okinawan waters and lush coastline, breezy tropical feel, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.citiesCategory.templates.baliTemplate',
        key: 54,
        image: '/background_templates/ci_6.jpg',
        prompt:
            'a professional photo of a product near a floor-to-ceiling window with panoramic views of Bali’s vibrant greenery and beaches, relaxed island mood, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.natureCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.natureCategory.templates.oceanTemplate',
        key: 55,
        image: '/background_templates/na_0.jpg',
        prompt:
            'a professional photo of a product in a minimalist interior with the vast ocean in the background, gentle coastal light, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.natureCategory.templates.desertTemplate',
        key: 56,
        image: '/background_templates/na_1.jpg',
        prompt:
            'a professional photo of a product placed in a setting that reveals endless golden sand dunes, warm desert lighting, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.natureCategory.templates.forestTemplate',
        key: 57,
        image: '/background_templates/na_2.jpg',
        prompt:
            'a professional photo of a product set in an environment where a dense pine forest serves as the backdrop, soft natural woodland light, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.natureCategory.templates.mountainsTemplate',
        key: 58,
        image: '/background_templates/na_3.jpg',
        prompt:
            'a professional photo of a product arranged against a mid-latitude mountain range featuring rolling green slopes, natural lighting, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
  {
    name: 'backgroundTemplates.fantasyCategory.name',
    templates: [
      {
        name: 'backgroundTemplates.fantasyCategory.templates.spaceshipTemplate',
        key: 59,
        image: '/background_templates/fa_0.jpg',
        prompt:
            'a professional photo of a product displayed in a futuristic space station, large observation window showing Earth below, advanced technology setting, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.fantasyCategory.templates.moonTemplate',
        key: 60,
        image: '/background_templates/fa_1.jpg',
        prompt:
            'a professional image of a product on a rugged moon-like surface, star-studded sky in the background, cosmic frontier ambiance, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.fantasyCategory.templates.spaceTemplate',
        key: 61,
        image: '/background_templates/fa_2.jpg',
        prompt:
            'a professional image of a product floating in deep space, surrounded by glowing stars and vibrant nebulae, vast galactic atmosphere, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
      {
        name: 'backgroundTemplates.fantasyCategory.templates.marsTemplate',
        key: 62,
        image: '/background_templates/fa_3.jpg',
        prompt:
            'a professional image of a product in an alien desert landscape with jagged rock formations and dusty red terrain, mysterious extraterrestrial mood, award winning photography',
        negativePrompt:
            'oversaturated, ugly, 3d, render, cartoon, grain, low-res, text, lens, camera',
      },
    ],
  },
]

export const TemplateCategory = ({
                                   name,
                                   templates,
                                   activeBackgroundKey,
                                   generateBackground,
                                   setActiveBackgroundKey,
                                   isStartAnimationOnGenerationButton,
                                 }: {
  name: string
  templates: any
  activeBackgroundKey: any
  generateBackground: (
      prompt: string,
      negativePrompt: string,
      name?: string,
      selectAiService?: boolean
  ) => void
  setActiveBackgroundKey: any
  isStartAnimationOnGenerationButton: any
}) => {
  const { t } = useTranslation()
  return (
      <div className="tool-panel_content_category">
        <h3 className="bodyM colorTextBlackPrimary" style={{ color: 'rgba(22, 27, 51, 0.6)' }}>
          {t(name)}
        </h3>
        <div className="tool-panel_content_category_wrapper mobile-flex-start">
          {templates.map((template: any, index: number) => (
              <AITemplate
                  key={index}
                  name={template.name}
                  prompt={template.prompt}
                  negativePrompt={template.negativePrompt}
                  activeBackgroundKey={activeBackgroundKey}
                  image={template.image}
                  generateBackground={generateBackground}
                  setActiveBackgroundKey={setActiveBackgroundKey}
                  activeKey={template.key}
                  isStartAnimationOnGenerationButton={isStartAnimationOnGenerationButton}
              />
          ))}
        </div>
      </div>
  )
}

export const AITemplate = ({
                             name,
                             activeKey,
                             prompt,
                             negativePrompt,
                             image,
                             activeBackgroundKey,
                             generateBackground,
                             setActiveBackgroundKey,
                             isStartAnimationOnGenerationButton,
                           }: {
  name: string
  activeKey: number
  prompt: string
  negativePrompt: string
  image: string
  activeBackgroundKey: any
  generateBackground: (
      prompt: string,
      negativePrompt: string,
      name?: string,
      selectAiService?: boolean
  ) => void
  setActiveBackgroundKey: any
  isStartAnimationOnGenerationButton: any
}) => {
  const store = usePolotno(s => s.store)
  const { t } = useTranslation()
  return (
      <div className="tool-panel_content_template">
        <div
            className={
              activeBackgroundKey == activeKey
                  ? 'tool-panel_content_template_image_block active'
                  : 'tool-panel_content_template_image_block'
            }
        >
          <img
              className="tool-panel_content_template_image"
              src={image}
              alt={t(name)}
              onClick={() => {
                setCustomStoreEntityProps(store, { currentPromptInStore: {prompt,negativePrompt}})
                reachGoal('click_template_background')
                setActiveBackgroundKey(activeKey)
                isStartAnimationOnGenerationButton()
              }}
          />
        </div>
        <span className="bodyS colorTextBlackSecondary center">{t(name)}</span>
      </div>
  )
}
