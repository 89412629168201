export const ANNUAL_DISCOUNT = 0.4

export const AFTER_REGISTRATION_PROMOTION_PROMOCODE = 'весна'
export const PROLONGATION_PLAN_PROMOCODE = 'save56'
export const PROLONGATION_PLAN_TAB = 'yearly'
export const UPGRADE_PLAN_PROMOCODE = 'save56'
export const UPGRADE_PLAN_TAB = 'yearly'
export const YEARLY_PLAN_TAB = 'yearly'


export const DAY_IN_MILLISECONDS = 24*60*60*1000
export const THREE_DAYS_IN_MILLISECONDS = 3*24*60*60*1000

export const PROLONGATION_USER_ID = [12587,15475,16070,16073,16214,16944,16945,18193,18235,18261,18291,18402,18622,18626,18651,18697,19116,19288,19326,19332,19358,19463,19518,21953,22640,24672,24699,24737,24759,24864,24875,25030,
  30069,30070,30071,30072,22368,20
]