import {Button} from "../../../Button";
import React, {MutableRefObject, useState} from "react";
import {PanelState, PromptRef} from "./types";
import {BackgroundType, createProjectStatParams, processBackground} from "../../utils/polotno.utils.js";
import { ReactComponent as DollarSymbol } from '../../dollar_symbol.svg'
import {StoreType} from "polotno/model/store";
import {updateCredits} from "../../../../api/updateCredits";
import {reachGoal} from "../../../../utils/metrics";
import {MODAL_NAME as CREDITS_MODAL} from "../../../../ds/modal/BuyCreditsModal";
import {usePolotno} from "../../../../store/usePolotno";
import {useCredits} from "../../../../store/useCredits";
import {useFullPageLoading} from "../../../../contexts/FullPageLoadingContext";
import {useSelectedBackgrounds, ValueType as BgMapType} from "../../../../store/useSelectedBackgrounds";
import {useModalManager} from "../../../../store/useModalManager";
import {getBackgroundGenerator, getPrompt, setCustomStoreEntityProps} from "../../utils/image.utils";
import {useImageStore} from "../../../../store/useImageStore";
import { hasCredits } from "../../utils/subscription.utils";
import { useTranslation } from 'react-i18next'
import { useClosePanelInMobile } from '../../../../hooks/useClosePanelInMobile'
import {GoToMultiAsset} from "./GoToMultiAsset";
import {useAuthContext} from "../../../../contexts/AuthProvider";
import {PositionChanged} from "../../../PositionChanged";
import {FormControlLabel, Switch} from "@mui/material";
import s from "../../components/RemoveBackgroundToggle.module.css";
import cx from "clsx";

type Props = {
    setPanelState: (p: PanelState) => void,
    store: StoreType,
    promptRef: MutableRefObject<PromptRef>
}

export const PromptPanel = ({setPanelState, store, promptRef}: Props) => {
    const [customPrompt, setCustomPrompt] = useState("")
    const [enhancePrompt, setEnhancePrompt] = useState(true)
    const product = usePolotno(s => s.product)
    const { credits, setCredits } = useCredits();
    const { setLoading } = useFullPageLoading()
    const selectedBackgrounds = useSelectedBackgrounds((s) => s.selectedBg)
    const selectBackground = useSelectedBackgrounds((s) => s.addBackgroundPair)
    const toggleModal = useModalManager((s) => s.toggleModal)
    const imageResultStore = useImageStore()
    const { t } = useTranslation()
    const generateBackground = getBackgroundGenerator({store, imageResultStore})
    const closePanelInMobile = useClosePanelInMobile()
    const [isMultiAssetHintShown, showMultiAssetHint] = useState(false)
    const { user } = useAuthContext()

    const updatePrompt = (prompt: any) => {
        promptRef.current = { prompt: prompt, negativePrompt: '' }
        setCustomStoreEntityProps(product, {currentPromptInStore: {...promptRef.current}})
        setCustomStoreEntityProps(store, { promptRefInStore: {
                prompt: promptRef.current.prompt,
                negativePrompt: promptRef.current.negativePrompt
            }})
    }

    const getPreviouslySelected = (thumb: string | undefined): BgMapType | null => {
        return thumb ? selectedBackgrounds[thumb] : null
    }
    const isActiveCard = (thumb: string | undefined) => !!getPreviouslySelected(thumb)

    const setActiveCard = (thumb: string, bg: string | null | undefined) => {
        if (thumb) {
            selectBackground(thumb, bg ?? '')
        }
    }

    const generateAndApply = async (prompt: string, name?: string) => {
        if (!prompt) return
        if (hasCredits(credits)) {
            if (isActiveCard(name)) return
            setLoading(true)
            updatePrompt(prompt)
            const res = await processBackground(store, product, generateBackground, prompt, enhancePrompt)
            if(res.credits){
                updateCredits(res.credits,setCredits)
                reachGoal("user_spent_credit", createProjectStatParams(store, product))
            }

            if(res.prompt){
                updatePrompt(res.prompt)
            }

            if (name) setActiveCard(name, '')
            setLoading(false)
        } else if (credits !== null && credits <= 0 && credits != -1) {
            toggleModal(CREDITS_MODAL, true)
        }
    }

    const generate = async () => {
        setCustomStoreEntityProps(product, { activeBackgroundType: BackgroundType.Prompt })
        await generateAndApply(customPrompt)
        showMultiAssetHint(true)
        user?.hideMultiAssetHint && closePanelInMobile()
    }
    return (
        <div className="tool-panel_content">
            <div className="tool-panel_content_text">
                <div
                    onClick={() => {
                        setPanelState(PanelState.Templates)
                    }}
                    style={{ float: 'left', cursor: 'pointer' }}
                >
                    <img src="/ArrowBackRounded.svg" alt={t('pages.editor.backButton')} />
                </div>
                <h2
                    className="titleS colorTextBlackPrimary"
                    style={{ width: '100%', textAlign: 'center' }}
                >
                    {t('pages.editor.createCustomBackground')}
                </h2>
            </div>
            { isMultiAssetHintShown && !user?.hideMultiAssetHint &&  <GoToMultiAsset store={store} crossClick={() => showMultiAssetHint(false)} />}
            <div
                className="tool-panel_content_wrapper content_custom_background_wrapper"
                style={{ gap: '10px' }}
            >
                <label className={'bodyS'} style={{ width: '306px' }}>
                    {t('pages.editor.describeBackground')}
                </label>
                <textarea
                    placeholder={t('pages.editor.backgroundExampleDescription')}
                    value={customPrompt}
                    className={'BodyM'}
                    onChange={(e) => setCustomPrompt(e.target.value)}
                    style={{
                        width: '306px',
                        height: '120px',
                        padding: '17px',
                        borderRadius: '20px',
                        border: '1px solid var(--Stroke-Default)',
                        resize: 'none',
                        outline: 'none',
                        WebkitTextSizeAdjust: 'none'
                    }}
                />

                <FormControlLabel
                    control={<Switch checked={enhancePrompt} onChange={()=>setEnhancePrompt(!enhancePrompt)} />}
                    className={s.toggle}
                    label={<span className={cx(s.toggleLabel, 'bodyM')}>Улучшить запрос</span>}
                    labelPlacement="start"
                />
            </div>
            <div className="tool-panel_content_category_warning">
                <div className={`position-warning-button ${customPrompt ? '' : 'disable'}`}>
                    <Button
                        className="warning-button"
                        size="m"
                        onClick={() => generate()}
                    >
                        <div className="warning-button-label">
                            {t('pages.editor.generateButton')}
                            <div className="warning-button-label-price">
                                <DollarSymbol style={{ marginTop: '-2px' }} />
                                <span>1</span>
                            </div>
                        </div>
                    </Button>
                </div>
            </div>
            <PositionChanged store={store}/>
        </div>
    )
}