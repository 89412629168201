import s from "../new-editor/sections/UploadPanel.module.css";
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import cx from "clsx";
import {Button} from "../Button";
import ms from "./modal.module.css"
import { informationTemplates } from "../new-editor/aicard/infographics";

export const MODAL_NAME = "informationSlidesUpload"

export const InformationSlidesUploadModal = ({onAddInformationSlide, close}: any) => {
    const { t } = useTranslation()
    const isScreenDesktop = true;
    const [activeInformationTemplate, setActiveInformationTemplate] = useState(0)

    const selectInformationTemplate = (key: number) => {
        setActiveInformationTemplate(key)
    }

    const onAddButtonHandler = () => {
        if(activeInformationTemplate !==0) {
            onAddInformationSlide(activeInformationTemplate)
            close()
        }
    }

    return (
        <div className={ms.body_modal}>
            <h2 className={cx(isScreenDesktop ? 'titleS' : 'bodyL', 'colorTextBlackPrimary')}>
                Выберите информационный слайд
            </h2>
            <div className={cx('dropzone', ms.upload_information_container)}>
                {informationTemplates.map((template: any) => {
                    return (
                        <div
                            key={template.key}
                            className={
                                activeInformationTemplate === template.key
                                    ? cx('information_elements', 'active')
                                    : cx('information_elements')
                            }
                            onClick={ () => selectInformationTemplate(template.key)}
                        >
                            <img
                                className={cx('tool-panel_content_template_image', 'information_img')}
                                src={template.image}
                                alt={template.alt}
                            />
                        </div>
                    )
                })}
            </div>

            <div className={cx(ms.buttons)}>
                <Button
                    type="button"
                    variation="tertiary"
                    size="m"
                    grow={true}
                    onClick={close}
                    style={{ width: '107px' }}
                >
                    <div className={s.add_images_button}>
                        <span>Отмена</span>
                    </div>
                </Button>
                <Button
                    type="button"
                    variation="primary"
                    size="m"
                    grow={true}
                    onClick={() => onAddButtonHandler()}
                    style={{ width: '227px' }}
                    disabled={activeInformationTemplate == 0}
                >
                    <div className={s.add_images_button}>
                        <span>Добавить</span>
                    </div>
                </Button>
            </div>
        </div>
    )
}