import { backendUrl } from "../../../config/app";

export const PROMO_EVENT_NAME = 'spring_after_registration'

export const getPromotionAnalyticsEvent = async (userId: number | null): Promise<any> => {
  const response = await fetch(`${backendUrl()}api/get-analytics?eventName=promo:${PROMO_EVENT_NAME}`, {
    method: 'POST',
  })

  return response.json()
}